@charset "UTF-8";

@font-face {
    font-family: 'Margarine';
    src: url('../fonts/Margarine-Regular.eot');
    src: url('../fonts/Margarine-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Margarine-Regular.woff2') format('woff2'),
        url('../fonts/Margarine-Regular.woff') format('woff'),
        url('../fonts/Margarine-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

li,
ul {
    margin: 0;
    padding: 0;
    list-style: none
}

a,
a:hover {
    text-decoration: none;
    transition: .5s ease-in-out;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -ms-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out
}

img {
    width: auto;
    max-width: 100%
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #010314;
}

::-webkit-scrollbar-thumb:hover {
    background: #010314;
}

html {
    font-family: 'Margarine';
    overflow-x: hidden !important;
}

body {
    font-family: 'Margarine';
    line-height: 24px;
    letter-spacing: 0.4px;
    background-color: #fff;
    font-size: 15px;
    color: #111;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    padding: 0 !important;
}

@-webkit-keyframes floaty {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(1rem);
    }
}

@keyframes floaty {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(1rem);
    }
}


/* ******************************************App_header_start**************************************** */

.App_header {
    background: transparent;
    padding: 5px 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.App_header .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.headersociallinks {
    position: fixed;
    right: 10px;
    top: 45%;
    z-index: 999;
    background: radial-gradient(circle, rgba(1, 197, 253, 1) 0%, rgba(164, 116, 80, 1) 100%);
    width: 50px;
    border-radius: 10px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s ease-in-out;
}

.headersociallinks:hover {
    width: 52px;
    height: 142px;
    transition: 0.5s ease-in-out;
}

.headersociallinks a {
    margin-bottom: 15px;
}

.headersociallinks a:last-child {
    margin-bottom: 0px;
}

.headersociallinks a img {
    width: 30px;
}

.App_header .navbar a img {
    width: 280px;
}

.App_header .navbar-collapse .navbar-nav li {
    padding: 0 5px;
}

.App_header .navbar-collapse .navbar-nav li:last-child {
    padding-right: 0px;
}

.App_header .navbar-collapse .navbar-nav a {
    font-size: 18px;
    color: #fff;
}


.App_header.fixed_header  {
    padding: 0px 0;
  }

  .App_header.fixed_header .navbar a img {
    width: 160px;
}

.fixed_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    z-index: 99;
    margin: 0 !important;
    margin: 0 auto;
    box-shadow: 0px 0px 10px 0px #3f51b547;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: #01c2fd97;
  }

  @keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }
  }


/* ******************************************App_header_End**************************************** */

/* ******************************************Footer_css_start**************************************** */

.App_footer {
    background: #01c5fd;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    z-index: 9999;
    position: relative;
}

.App_footer .footer_logo {
    margin-bottom: 15px;
}

.App_footer .footer_logo img {
    width: 350px;
}

.App_footer p {
    font-size: 20px;
    font-weight: 500;
    color: #efefef;
    margin: 0;
    margin-top: 20px;
}
.App_footer p a {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin: 0;
    margin-top: 20px;
}

.sociallinks {
    display: flex;
    margin-top: 0px;
    justify-content: center;
}

.sociallinks a {
    margin-right: 10px;
    margin-bottom: 0;
}

.sociallinks img {
    width: 30px;
}

/* ******************************************Footer_css_End**************************************** */

/* ******************************************Home_page_css_start**************************************** */

.main_home_page_css {
    position: relative;
}

.main_home_page_css .main_slider_top {
    position: relative;
    background-color: black;
    width: 100%; /* Adjust this as per your layout */
    height: 100%; /* Adjust this as per your layout */
}

.main_home_page_css .main_slider_top .backgroundvideo {
    width: 100%;
    max-width: 100%;
    position: relative;
}

.main_home_page_css .main_slider_top iframe {
    width: 100%;
    /* height: 100%; */
    /* min-height: 400px; */
}

.main_home_page_css .main_slider_top .innervideo {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background: transparent !important;
    object-fit: cover;
}

.main_home_page_css .claimsbt {
    position: relative;
    width: 100%;
    height: 100%;
}

.main_home_page_css .claimsbt .backgroundvideo {
    /* position: absolute; */
    object-fit: cover;
    width: 100%;
    max-width: 1600px;
}

.main_home_page_css .claimsbt .bannercontent {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    /* min-height: 800px; */
    background-color: #00000075;
    /* padding-bottom: 240px; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 700px;
}

.main_home_page_css .claimsbt .bannercontent h1 {
    font-size: 45px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 2px 2px #02c5fc;
    margin-bottom: 30px;
}

.main_home_page_css .claimsbt .bannercontent span {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    display: block;
    margin-bottom: 20px;
}

.main_home_page_css .claimsbt .bannercontent p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    width: 100%;
    max-width: 650px;
    margin: auto;
    margin-bottom: 25px;
}

.main_home_page_css .claimsbt .bannercontent button {
    background: #01c5fd;
    padding: 15px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #00000057;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.5s ease-in-out;
    position: relative;
    border: none;
}


.main_custom_popup {
    position: fixed;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    background: #28354f5c;
    left: 0;
    top: 0;
    padding: 20px;
    margin: auto;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}

.main_custom_popup .main_custom_popup_inner {
    border: 1px solid #ddd;
    border-radius: 10px;
    height: auto;
    position: relative;
    background: #fff;
    width: 350px;
    margin: auto;
    margin-top: 0;
    box-shadow: 0px 0px 10px 0px var(--main_primary)6b;
    overflow: hidden;
}

#login_popup_main.main_custom_popup .main_custom_popup_inner {
    width: 100%;
    max-width: 500px;
    background-image: linear-gradient(45deg, rgb(227 218 255) 0, rgb(232 225 255) 21%, rgb(236 234 255) 21%, rgb(234 232 255) 30%, rgb(226 231 255) 30%, rgb(241 243 255) 35%, rgb(226 237 255) 35%, rgb(248 251 255) 51%, rgb(248 252 255) 51%, rgb(239 248 255) 72%, rgb(247 245 255) 72%, rgb(243 242 255) 81%, rgb(237 238 255) 81%, rgb(236 253 255) 100%);
    margin: auto;
}
.main_custom_popup .main_custom_popup_inner .close_btn_pop {
    position: absolute;
    right: 21px;
    top: 10px;
    cursor: pointer;
    color: #d24a4a;
    font-size: 24px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_header {
    font-size: 20px;
    font-weight: 600;
    color: #10498a;
    padding: 11px 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
    padding: 5px 20px 15px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 320px;
}

.main_custom_popup .main_custom_popup_inner.custom_popup_width .main_custom_popup_inner_body {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: none;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body::-webkit-scrollbar {
    width: 0px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data h1 {
    font-size: 48px;
    font-weight: 600;
    color: #00BCD4;
    line-height: 31px;
    margin-bottom: 30px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data p {
    font-size: 18px;
    font-weight: 600;
    color: #111;
    line-height: 31px;
    margin-bottom: 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data a {
    background: #00BCD4;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px #00bcd48c;
    margin: 0 20px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data a img {
    width: 25px;
}

.airdropcentercontent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.customcheckboxinsure {
    margin-bottom: 40px;
    width: auto;
    position: relative;
    transition: 0.5s ease-in-out;
}

.customcheckboxinsure .inputCheckbox:checked {
    background-color: #02c5fc;
    border: 1px solid #02c5fc;
    transition: 0.5s ease-in-out;
}

.customcheckboxinsure .inputCheckbox {
    -webkit-appearance: none;
    outline: none;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #02c5fc;
    vertical-align: middle;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 0.5s ease-in-out;
}

.customcheckboxinsure .inputCheckbox:checked:before {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 8px;
    top: 2px;
    transition: 0.5s ease-in-out;
}

.customcheckboxinsure label {
    padding: 0px 0px 0px 35px;
    cursor: pointer;
    font-size: 15px;
    color: #efefef;
    display: flex;
    align-items: center;
    transition: 0.5s ease-in-out;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.customcheckboxinsure .inputCheckbox:checked+label {
    color: #02c5fc;
    transition: 0.5s ease-in-out;
}

.main_home_page_css .claimsbt .bannercontent button:hover {
    background: linear-gradient(180deg, #00c0fe 8%, #d4cec0 100%);
    transition: 0.5s ease-in-out;
}

.section_overlay_about {
    background: linear-gradient(180deg, rgba(212, 206, 192, 1) 38%, rgb(0 192 254) 100%);
    padding: 1px 0;
    text-align: center;
    margin-top: -8px;
    position: relative;
    z-index: 2;
}

.section_overlay_about h2 {
    font-size: 70px;
    color: #111;
    margin-top: 80px;
    position: relative;
    z-index: 10;
    font-weight: 900;
    text-shadow: 3px 3px #fff;
}

.section_overlay_about h3{
    font-size: 30px;
    font-weight: 900;
    color: #111;
    text-shadow: 1px 1px #02c5fc;
}

.section_overlay_about p {
    font-size: 18px;
    z-index: 10;
    max-width: 900px;
    line-height: 32px;
    color: #111;
    position: relative;
    margin: auto;
}

.section_overlay_about2 {
    background: linear-gradient(180deg, rgb(242 247 248) 38%, #01c5fd 100%);
    padding: 70px 0;
    padding-top: 100px !important;
    text-align: center;
    margin-top: -8px;
    position: relative;
    z-index: 2;;
}

.section_overlay_about2 h3 {
    font-size: 56px;
    color: #01c5fd;
    line-height: 74px;
    font-weight: 700;
    max-width: 800px;
    margin: auto;
    margin-bottom: 10px;
    text-shadow: 2px 2px #000;
}

.section_overlay_about2 .pointsTitle {
    font-size: 18px;
    z-index: 10;
    max-width: 900px;
    line-height: 32px;
    color: #111;
    position: relative;
    margin: auto;
    margin-top: 25px;
}

.section_overlay_about2 .pointsTitle .innerabout {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.main_home_page_css .about_block {
    position: relative;
    margin-top: -100px;
}

.main_home_page_css .about_block .aboutvideo {
    width: 100%;
    max-width: 100%;
    position: relative;
}

.main_home_page_css .about_block .innerabout {
    position: absolute;
    z-index: 19;
    width: 100%;
    max-width: 700px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
}

.section_overlay_csscroll.section_overlay_csscroll2{
    background: linear-gradient(120deg, #01c5fd 38%, #01c5fd 100%);
    padding-top: 40px;
}

.section_overlay_csscroll {
    background: linear-gradient(120deg, rgb(165 240 253) 38%, rgb(0 199 253) 100%);
    padding: 10px 0px;
    margin-top: -8px;
    position: relative;
}

.section_overlay_csscroll marquee{
    font-size: 54px;
    font-weight: 900;
    height: 70px;
    line-height: 58px;
    color: #fff;
    text-shadow: 2px 2px 2px #111;
    letter-spacing: 2px;
    font-style: italic;
}

.section_overlay_csscroll marquee img {
    width: 83px;
}

.main_home_page_css .inner_overlay_animation {
    position: relative;
    background: #f2f7f8;
    padding-top: 60px;
    padding-bottom: 0px;
}

.main_home_page_css .inner_overlay_animation.gameplay .backgroundvideo {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 45px;
}

.main_home_page_css .inner_overlay_animation.withteam::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, #01c5fd 0%, #f2f7f8 95%);
    height: 150px;
}

.main_home_page_css .inner_overlay_animation.withteam {
    padding-top: 100px;
    padding-bottom: 50px;
}

.main_home_page_css .inner_overlay_animation h3 {
    font-size: 56px;
    color: #01c5fd;
    line-height: 74px;
    font-weight: 700;
    max-width: 800px;
    margin: auto;
    margin-bottom: 10px;
    text-shadow: 2px 2px #000;
    text-align: center;
}

.main_home_page_css .inner_overlay_animation .mainrowcenter {
    justify-content: center;
    margin-bottom: 45px;
}
.roadmapmain {
    margin-top: 30px;
}
.roadmapmain .roadmap-item {
    position: relative;
    z-index: 0;
}
.roadmapmain .roadmap-item .roadmap-title {
    color: #00c0fe;
    display: block;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .56px;
    margin-bottom: 25px;
    margin-left: 14px;
    text-transform: uppercase;
}
.roadmapmain .roadmap-item .roadmap-content {
    background-color: #01c6fd19;
    border: 2px solid #01c5fd;
    border-radius: 15px;
    min-height: 235px;
    padding: 25px 20px 10px;
    position: relative;
}
.roadmapmain .roadmap-item .roadmap-content:after,
.roadmapmain .roadmap-item .roadmap-content:before {
    background: radial-gradient(34.71% 34.71% at 56.45% 63.48%, #54d19b 23.4400004148%, #54d19b 72.9200005531%);
    content: "";
    filter: blur(32px);
    height: 192px;
    opacity: .1;
    transition: .3s;
    width: 192px;
    z-index: -1;
}
.roadmapmain .roadmap-item .roadmap-content:before {
    bottom: -59px;
    position: absolute;
    right: -36px;
}
.roadmapmain .roadmap-item .roadmap-content:after {
    left: -36px;
    position: absolute;
    top: -59px;
}
.roadmapmain .roadmap-item .roadmap-content .dot {
    background: #fff;
    border-radius: 50%;
    display: block;
    flex: 0 0 auto;
    height: 32px;
    margin-bottom: 25px;
    position: relative;
    transform: translateY(3px);
    width: 32px;
}
.roadmapmain .roadmap-item .roadmap-content .dot:before {
    background: linear-gradient(0deg, #000, #00c0fe 47.6%, #00c0fe 98.23%);
    border-radius: 50%;
    content: "";
    height: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
}
.roadmapmain .roadmap-item .roadmap-content .dot:after {
    background: #292a37;
    bottom: 24px;
    content: "";
    height: 65px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 1px;
}
.roadmapmain .roadmap-item .roadmap-content p {
    align-items: baseline;
    color: #4b4b4b;
    display: flex;
    font-size: 13px;
    text-align: left;
}
.roadmapmain .roadmap-item .roadmap-content p b {
    width: 20px;
    height: 20px;
    background: #efefef;
    border: 1px solid #01c5fd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    color: #01c1fd;
    margin-right: 8px;
}

.roadmapmain .roadmap-item .roadmap-content p small {
    width: calc(100% - 20px);
    color: #4b4b4b;
    font-size: 13px;
}

.teamTitle {
    margin-top: 50px !important;
}
.main_home_page_css .inner_overlay_animation .teamBox {
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: 30px;
    overflow: hidden;
    padding: 0px;
    background-color: #01c6fd19;
    border: 2px solid #01c5fd;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.main_home_page_css .inner_overlay_animation .inner_data {
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: 30px;
    padding: 25px;
    background-color: #01c6fd19;
    border: 2px solid #01c5fd;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    min-height: 368px;
}

.main_home_page_css .inner_overlay_animation .inner_data.inner_data_full {
    text-align: left;
    height: auto;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_home_page_css .inner_overlay_animation .inner_data img {
    width: 200px;
}

.main_home_page_css .inner_overlay_animation .inner_data h4 {
    font-size: 36px;
    color: #01c5fd;
    font-weight: 700;
    text-shadow: 1px 1px #000;
    margin-bottom: 0px;
    margin-top: 20px;
}

.main_home_page_css .inner_overlay_animation .inner_data p {
    font-size: 14px;
    color: #4b4b4b;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

.main_home_page_css .inner_overlay_animation .inner_data_wallet {
    width: 100%;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
}

.main_home_page_css .inner_overlay_animation .inner_data_wallet span {
    font-size: 14px;
    color: #01c5fd;
    background-color: #01c6fd19;
    border: 2px solid #01c5fd;
    padding: 10px 15px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    width: auto;
    text-align: center;
    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}

.main_home_page_css .inner_overlay_animation .inner_data_wallet span:hover {
    font-size: 16px;
    padding: 15px 20px;
    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}

.howtobuy {
    position: relative;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    padding: 100px 0px;
    text-align: center;
}

.tokefeatures {
    position: relative;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 100px 0px;
    text-align: center;
}

.howtobuy .heading_tag {
    position: relative;
    z-index: 1;
}

.howtobuy .heading_tag h2 {
    font-size: 60px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 40px;
    text-shadow: 3px 3px #000;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.howtobuy .content_box {
    padding: 0;
    position: relative;
    text-align: center;
    padding-top: 50px;
}

.howtobuy .content_box .innervideo {
    position: absolute;
    z-index: 19;
    width: 100%;
    max-width: 700px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
}

.tokefeatures .heading_tag {
    position: relative;
    z-index: 1;
}

.tokefeatures .heading_tag h2 {
    font-size: 60px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 40px;
    text-shadow: 3px 3px #000;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.tokefeatures ul li {
    padding: 0;
    position: relative;
    text-align: center;
    padding-top: 15px;
    display: flex;
    align-items: center;
}

.tokefeatures ul li img {
    width: 100%;
    max-width: 140px;
}

.tokefeatures ul li span {
    font-size: 28px;
    color: #111;
    display: block;
    padding-left: 10px;
    letter-spacing: 2px;
    font-weight: 700;
}

.tokefeatures .tokefeaturesvideoblock {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tokefeatures .tokefeaturesvideoblock img {
    width: 100%;
    max-width: 600px;
}

.tokefeatures .tokefeaturesvideoblock .innervideo {
    width: 100%;
    max-width: 600px;
}

.navbar-toggler {
    background: #01c5fd;
    border: none;
    color: #fff;
    width: 50px;
    font-size: 25px;
    border: 1px solid #111;
    box-shadow: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.main_pagenotfound {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  place-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 64px;
  background: #01c5fd;
}

.main_pagenotfound h1 {
  font-size: clamp(10vw, 20vw, 300px);
  opacity: 0;
  transform: translateY(-100vh);
}
.main_pagenotfound h2 {
  font-size: clamp(3vw, 7vw, 100px);
  color: transparent;
  text-shadow: 2px 2px 0 red, -2px -2px 0 green, 6px 0 0 violet, -6px 0 0 yellow,
    0 -6px 0 aqua, 0 6px 0 yellowgreen;
  color: #eee;
}
.main_pagenotfound h1,
.main_pagenotfound h2 {
  margin: 0;
  line-height: clamp(5vw, 10vw, 150px);
}
.main_pagenotfound .material-symbols-outlined {
  font-size: clamp(10vw, 20vw, 300px);
  text-shadow: 2px 2px 0 red, -2px -2px 0 green, 6px 0 0 violet, -6px 0 0 yellow,
    0 -6px 0 cyan, 0 6px 0 yellowgreen;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.cs_custom_lodar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	background-color: #00bbd440;
	-webkit-backdrop-filter: blur(32px);
	backdrop-filter: blur(32px);
  }

  .cs_custom_lodar .inner_div {
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 6px;
	margin: auto;
	top: 45%;
	position: absolute;
	right: 0;
	left: 0;
  }

  .cs_custom_lodar .inner_div .containerddd {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    filter: url('#goo');
    animation: rotate-move 2s ease-in-out infinite;
  }

  .cs_custom_lodar .inner_div .containerddd .dot {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .cs_custom_lodar .inner_div .containerddd .dot-3 {
    background-color: #f74d75;
    animation: dot-3-move 2s ease infinite, index 6s ease infinite;
  }

  .cs_custom_lodar .inner_div .containerddd .dot-2 {
    background-color: #463e1f;
    animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
  }

  .cs_custom_lodar .inner_div .containerddd .dot-1 {
    background-color: #ffe386;
    animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
  }

  @keyframes dot-3-move {
    20% {transform: scale(1)}
    45% {transform: translateY(-18px) scale(.45)}
    60% {transform: translateY(-90px) scale(.45)}
    80% {transform: translateY(-90px) scale(.45)}
    100% {transform: translateY(0px) scale(1)}
  }

  @keyframes dot-2-move {
    20% {transform: scale(1)}
    45% {transform: translate(-16px, 12px) scale(.45)}
    60% {transform: translate(-80px, 60px) scale(.45)}
    80% {transform: translate(-80px, 60px) scale(.45)}
    100% {transform: translateY(0px) scale(1)}
  }

  @keyframes dot-1-move {
    20% {transform: scale(1)}
    45% {transform: translate(16px, 12px) scale(.45)}
    60% {transform: translate(80px, 60px) scale(.45)}
    80% {transform: translate(80px, 60px) scale(.45)}
    100% {transform: translateY(0px) scale(1)}
  }

  @keyframes rotate-move {
    55% {transform: translate(-50%, -50%) rotate(0deg)}
    80% {transform: translate(-50%, -50%) rotate(360deg)}
    100% {transform: translate(-50%, -50%) rotate(360deg)}
  }

  @keyframes index {
    0%, 100% {z-index: 3}
    33.3% {z-index: 2}
    66.6% {z-index: 1}
  }

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .main_home_page_css .main_slider_top .innervideo {
        max-width: 650px;
    }
    .main_home_page_css .about_block .innerabout {
        max-width: 550px;
    }
}
@media (max-width:1199.9px) {

    .main_home_page_css .main_slider_top .innervideo {
        max-width: 600px;
    }
    .section_overlay_about h2 {
        font-size: 60px;
        margin-top: 20px;
    }
    .section_overlay_about p {
        font-size: 16px;
        line-height: 28px;
    }
    .main_home_page_css .about_block .innerabout {
        max-width: 480px;
        bottom: 0px;
    }
    .tokefeatures .heading_tag h2 {
        font-size: 50px;
        margin-bottom: 50px;
    }
    .tokefeatures ul li img {
        max-width: 100px;
    }
    .tokefeatures ul li span {
        font-size: 25px;
    }
    .tokefeatures .tokefeaturesvideoblock .innervideo {
        margin-top: 50px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data h4 {
        font-size: 28px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data p {
        font-size: 14px;
    }
    .section_overlay_csscroll marquee {
        font-size: 34px;
        height: 50px;
    }
    .section_overlay_csscroll marquee img {
        width: 70px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data {
        min-height: 350px;
    }
    .tokefeatures .tokefeaturesvideoblock img {
        max-width: 340px;
    }
    .App_header .navbar-collapse .navbar-nav a {
        font-size: 16px;
    }
    .App_header .navbar a img {
        width: 230px;
    }
    .roadmapmain .roadmap-item .roadmap-content {
        min-height: 260px;
    }
}
@media (max-width: 991.9px) {
    .main_home_page_css .claimsbt .backgroundvideo {
        width: 999px;
    }
    .main_home_page_css .claimsbt .bannercontent {
        padding-bottom: 90px;
    }
    .main_home_page_css .main_slider_top .innervideo {
        max-width: 400px;
    }
    .section_overlay_about h2 {
        font-size: 50px;
    }
    .main_home_page_css .about_block .innerabout {
        max-width: 340px;
    }
    .tokefeatures ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .tokefeatures ul li {
        width: calc(100% / 2);
    }
    .tokefeatures ul li span {
        font-size: 24px;
        text-align: left;
    }
    .main_home_page_css .inner_overlay_animation h3 {
        font-size: 46px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data {
        min-height: 320px;
    }
    .App_header .navbar-collapse .navbar-nav a {
        font-size: 12px;
    }
    .App_header .navbar a img {
        width: 150px;
    }
    .roadmapmain .roadmap-item .roadmap-content {
        min-height: 210px;
        margin-bottom: 35px;
    }
}
@media (max-width: 767.9px) {
    .main_home_page_css .inner_overlay_animation .overlay_data .inner_data h3,
    .main_home_page_css .about_block h2 {
        font-size: 60px;
    }
    .main_home_page_css .inner_overlay_animation .overlay_data .inner_data p,
    .main_home_page_css .about_block p {
        font-size: 17px;
        line-height: 24px;
        text-align: center;
    }
    .footer_logo {
        text-align: center;
    }
    .main_home_page_css .main_slider_top .innervideo {
        max-width: 280px;
        bottom: 20px;
    }
    .main_home_page_css .about_block .innerabout {
        max-width: 240px;
    }
    .tokefeatures {
        padding: 50px 0px;
    }
    .tokefeatures ul li span {
        font-size: 20px;
        line-height: 35px;
    }
    .main_home_page_css .about_block {
        position: relative;
        margin-top: -29px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data.inner_data_full {
        text-align: center;
        flex-direction: column;
    }
    .main_home_page_css .inner_overlay_animation .inner_data {
        min-height: 340px;
    }
    .roadmapmain .roadmap-item .roadmap-content {
        min-height: 240px;
    }
}
@media (max-width: 576.9px) {
    .main_home_page_css .claimsbt .backgroundvideo {
        width: 1199px;
    }
    .tokefeatures .heading_tag h2,
    .main_home_page_css .about_block h2 {
        font-size: 30px;
    }
    .main_home_page_css .about_block {
        padding: 0px 0;
    }
    .main_home_page_css .about_block .background_banner {
        margin-bottom: 45px;
    }
    .section_overlay {
        height: 40px;
        box-shadow: 0px 170px 80px 180px #010314;
    }
    .main_home_page_css .inner_overlay_animation .overlay_data .inner_data .move_slide {
        margin-bottom: 0px;
    }
    .App_footer .col-md-3.col-sm-4:nth-child(3),
    .App_footer .col-md-3.col-sm-4:nth-child(4){
        display: none;
    }
    .App_footer .footer_menu_item ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .App_footer .footer_menu_item ul li {
        padding: 5px 8px;
    }
    .App_footer .footer_menu_item ul li a {
        font-size: 14px;
    }
    .App_header .navbar a img {
        width: 150px;
    }
    .main_home_page_css .main_slider_top .innervideo {
        max-width: 250px;
        bottom: 5px;
    }
    .section_overlay_about h2 {
        font-size: 30px;
    }
    .section_overlay_about p {
        font-size: 14px;
        line-height: 20px;
    }
    .main_home_page_css .about_block {
        margin-top: 0px;
    }
    .tokefeatures ul li {
        width: calc(100% / 1);
        padding-top: 12px;
    }
    .App_header {
        background: #00BCD4;
        padding: 0px 0;
        position: relative;
    }
    .main_home_page_css .inner_overlay_animation .inner_data {
        min-height: unset;
    }
    .roadmapmain .roadmap-item .roadmap-content {
        min-height: unset;
    }
}
@media (max-width: 475px) {
    .tokefeatures a {
        margin-top: 0px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data_wallet span:hover,
    .main_home_page_css .inner_overlay_animation .inner_data_wallet span {
        font-size: 12px;
    }
    .main_home_page_css .inner_overlay_animation h3 {
        font-size: 30px;
    }
}
@media (max-width: 375px) {
    .main_home_page_css .about_block .innerabout {
        max-width: 200px;
    }
    .main_home_page_css .inner_overlay_animation .inner_data h4 {
        font-size: 20px;
        font-weight: 500;
    }
}
/* ******************************************Home_page_css_End**************************************** */